import "../App.css";
import { useMemo, useState } from "react";
import { useEffect } from "react";
import io from "socket.io-client";
import moment from "moment-timezone";
import Blocked from "../components/Blocked";
import Carousel from "react-material-ui-carousel";
import MarketClose from "../components/MarketClose";
import bullionStats from "../assets/bullioStats.png";
import alkhamisLogo from "../assets/alkhamis-logo.png";
import { Box, Modal, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { commodityCalculation } from "../components/constants";
import EconomicNewsModal from "../components/EconomicNewsModal";
import SubscriptionExpired from "../components/SubscriptionExpired";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";

const { makeStyles } = require("@mui/styles");

let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100vh",
    backgroundColor: "#1C1C1A",
  },
  mainContainer: {
    width: "100%",
    height: "90vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1.3fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    padding: "3rem 3rem 1rem 3rem ",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1.3fr 2fr 1fr",
    gridTemplateAreas: `
        'logo' 
        'spotRate'
        'stat'
      `,
  },
  logo: {
    gridArea: "logo",
  },
  leenLogoImg: {
    width: "100%",
    height: "auto",
  },
  spotRate: {
    gridArea: "spotRate",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px 15px 0px 0px",
    border: "1px solid #B99A41",
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col3: {
    gridArea: "spotRateBoxSilverRow2Col3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexBasis: 0,
    flexShrink: 1,
    width: "7vw",
  },
  stat: {
    gridArea: "stat",
    border: "1px solid #B99A41",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  bullionStatsImg: {
    width: "150px",
    height: "auto",
  },
  ////////////////////////////////////////////////////////
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1.3fr 3fr",
    gridTemplateAreas: `
        'banner' 
        'table'
      `,
  },

  //////////////////////////////////////
  banner: {
    gridArea: "banner",
    // width: "2fr",
    display: "grid",
    gridTemplateColumns: "2.3fr 1fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
      'bannerImg dateTime'
    `,
    paddingRight: "1em",
  },
  bannerImg: {
    gridArea: "bannerImg",
    width: "100%",
    objectFit: "contain",
    boxSizing: "border-box",
  },
  dateTime: {
    gridArea: "dateTime",
    color: "#D3AA4C",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  //////////////////////////////////////
  table: {
    gridArea: "table",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 5fr",
    gridTemplateAreas: `
      'tabeHeader' 
      'tableContent'
    `,
    gap: ".5em",
    // padding: "1em",
    border: "1px solid #B99A41",
    borderRadius: "15px 15px 0px 0px",
    boxSizing: "border-box",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#D3AA4C",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    borderRadius: "15px 15px 0px 0px",
    alignItems: "center",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    padding: "0em 1em 1em 1em",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "center",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "17%",
    flexGrow: 1,
    gridArea: "tableRow1",
    border: "1px solid #D3AA4C",
    borderRadius: "5px",
    background: "#2F2E2C",
    display: "flex",
  },
  //////////////////////////////////
  updates: {
    gridArea: "updates",
    height: "10vh",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },

  updatesContent: {
    gridArea: "updatesContent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70%",
    borderBottom: "1px solid #B99A41",
    borderTop: "1px solid #B99A41",
    width: "100%",
    color: "#fff",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [commodities, setCommodities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [goldNews, setGoldNews] = useState({});

  ////////////Block//////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };
  ///////////////////// SubscriptionExpierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();
  ///////////////////// SubscriptionExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()?.tz(adminData?.time_zone);
      const dayOfWeek = now?.day();
      const hour = now?.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      window.location.reload();
      // handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };
  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handelFindBanners();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };
  const formatDate = () => moment(new Date()).format("DD MMM YYYY");
  const formatTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const buyersColor = "#2B85DC";
  const sellersColor = "#DE5B56";
  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  return (
    <div>
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
          <Box className={classes.leftPart}>
            <Box className={classes.logo}>
              <img
                className={classes.leenLogoImg}
                src={alkhamisLogo}
                alt="alkhamisLogo"
              />
            </Box>
            <Box className={classes.spotRate}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "23%",
                  borderRadius: "15px 15px 0px 0px",
                  background: "#B99A41",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.4vw",
                    fontWeight: "bold",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  SPOT RATE
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Box
                    sx={{
                      color: "#fff",
                      background: "black",
                      px: "7px",
                      display: "inline-block",
                      width: "fit-content",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw", fontWeight: "bold" }}>
                      $
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Box
                    sx={{
                      color: "#fff",
                      background: "black",
                      px: "7px",
                      display: "inline-block",
                      width: "fit-content",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw", fontWeight: "bold" }}>
                      $
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.askOrSell?.toUpperCase()}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "7vw",
                  }}
                >
                  <Typography sx={{ fontSize: "1vw", fontWeight: "bold" }}>
                    LOW/
                  </Typography>
                  <Typography sx={{ fontSize: "1vw", fontWeight: "bold" }}>
                    HIGH
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "42%",
                  display: "flex",
                  color: "#FFFF",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.2vw",
                      fontWeight: "bold",
                    }}
                  >
                    GOLD
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.2vw",
                      fontWeight: "bold",
                    }}
                  >
                    OZ
                  </Typography>
                </Box>

                <Box className={classes.spotRateBoxGoldRow2Col1}>
                  <Box
                    sx={{
                      background:
                        gold?.cur?.bid < gold?.pre?.ask
                          ? "#DE5B56"
                          : gold?.cur?.bid > gold?.pre?.ask
                          ? "#5FA147"
                          : "#2F2E2C",
                      border: "1px solid #fff",
                      color: "#fff",
                      borderRadius: "5px",
                      width: "7.3vw",
                      height: "6.3vh",
                      display: "flex",
                      alignItems: "center",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(!gold?.cur?.bid
                        ? 0
                        : Number(spread.goldBidSpread) + gold?.cur?.bid
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.spotRateBoxGoldRow2Col1}>
                  <Box
                    sx={{
                      background:
                        gold?.cur?.ask < gold?.pre?.ask
                          ? "#DE5B56"
                          : gold?.cur?.ask > gold?.pre?.ask
                          ? "#5FA147"
                          : "#2F2E2C",
                      border: "1px solid #fff",
                      color: "#fff",
                      borderRadius: "5px",
                      width: "7.3vw",
                      height: "6.3vh",
                      display: "flex",
                      alignItems: "center",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(!gold?.cur?.ask
                        ? 0
                        : Number(spread.goldAskSpread) + gold?.cur?.ask
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBoxSilverRow2Col3}>
                  <Box
                    sx={{
                      color: "#B1362D",
                      width: "7vw",
                      display: "flex",
                      alignItems: "center",
                      px: 1.5,
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.1vw",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {(!gold?.cur?.lowPrice
                        ? 0
                        : Number(spread.goldLowSpread) + gold?.cur?.lowPrice
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      color: "#2DB13A",
                      width: "7.3vw",
                      display: "flex",
                      justifyContent: "center",
                      px: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.1vw",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {(!gold?.cur?.bid
                        ? 0
                        : Number(spread.goldHighSpread) + gold?.cur?.highPrice
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "42%",
                  display: "flex",
                  color: "#FFFF",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.2vw",
                      fontWeight: "bold",
                    }}
                  >
                    SILVER
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.2vw",
                      fontWeight: "bold",
                    }}
                  >
                    Oz
                  </Typography>
                </Box>

                <Box className={classes.spotRateBoxSilverRow2Col1}>
                  <Box
                    sx={{
                      background:
                        silver?.cur?.bid < silver?.pre?.bid
                          ? "#DE5B56"
                          : silver?.cur?.bid > silver?.pre?.bid
                          ? "#5FA147"
                          : "#2F2E2C",
                      border: "1px solid #fff",
                      color: "#fff",
                      borderRadius: "5px",
                      px: 1.5,
                      width: "7.3vw",
                      height: "5vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.6vw" }}>
                      &nbsp;&nbsp;
                      {(!silver?.cur?.bid
                        ? 0
                        : Number(spread.silverAskSpread) + silver?.cur?.bid
                      ).toFixed(3)}
                      &nbsp;&nbsp;
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBoxSilverRow2Col2}>
                  <Box
                    sx={{
                      background:
                        silver?.cur?.ask < silver?.pre?.ask
                          ? "#DE5B56"
                          : silver?.cur?.ask > silver?.pre?.ask
                          ? "#5FA147"
                          : "#2F2E2C",
                      border: "1px solid #fff",
                      color: "#fff",
                      borderRadius: "5px",
                      px: 1.5,

                      width: "7.3vw",
                      height: "5vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.6vw" }}>
                      &nbsp;&nbsp;
                      {(!silver?.cur?.ask
                        ? 0
                        : Number(spread.silverBidSpread) + silver?.cur?.ask
                      ).toFixed(3)}
                      &nbsp;&nbsp;
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.spotRateBoxSilverRow2Col3}>
                  <Box
                    sx={{
                      color: "#B1362D",
                      width: "7.3vw",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      px: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.1vw",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {(!silver?.cur?.bid
                        ? 0
                        : Number(spread.silverLowSpread) + silver?.cur?.lowPrice
                      ).toFixed(3)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      color: "#2DB13A",
                      width: "7.3vw",
                      justifyContent: "center",
                      display: "flex",
                      px: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.1vw",
                        fontWeight: "bold",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      {(!silver?.cur?.bid
                        ? 0
                        : Number(spread.silverHighSpread) +
                          silver?.cur?.highPrice
                      ).toFixed(3)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.stat}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 5,
                  py: 1,
                  boxSizing: "border-box",
                }}
              >
                <Typography sx={{ fontSize: "1.3vw" }}>BUYERS</Typography>
                <Typography
                  sx={{
                    color:
                      Number(ratio?.chgValue.replace("%", "")) >= 0
                        ? "#2B85DC"
                        : "DE5B56",
                    fontSize: "1.3vw",
                  }}
                >
                  {ratio?.chgValue}
                </Typography>
                <Typography sx={{ fontSize: "1.3vw" }}>SELLERS</Typography>
              </Box>
              <Box sx={{ width: "90%", px: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={progressStyle}
                  sx={{
                    "& .MuiLinearProgress-bar": barStyle,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 7,
                  py: 1,
                  boxSizing: "border-box",
                }}
              >
                <Typography sx={{ fontSize: "1.3vw", color: "#2B85DC" }}>
                  {ratio?.Buyers}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <a href="https://www.bullionstats.com/">
                    <img
                      className={classes.bullionStatsImg}
                      src={bullionStats}
                      alt="bullionStats"
                    />
                  </a>
                </Box>
                <Typography sx={{ fontSize: "1.3vw", color: "#DE5B56" }}>
                  {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.rightPart}>
            <Box className={classes.banner}>
              <Box className={classes.bannerImg}>
                <Carousel
                  animation="fade" // Set the animation type to slide
                  navButtonsAlwaysVisible={false} // Show navigation buttons always
                  interval={3000}
                  indicatorContainerProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  sx={{
                    height: "23vh",
                  }}
                  transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                  autoPlay
                >
                  {banners?.map((row, i) => (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "22vh",
                        objectFit: "cover",
                        borderRadius: "20px",
                      }}
                      src={row.imageUrl}
                      alt="banner"
                    />
                  ))}
                </Carousel>
              </Box>

              <Box className={classes.dateTime}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "2.8vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {formatTime()}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "2vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {formatDate().toUpperCase()}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.table}>
              <Box className={classes.tabeHeader}>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  COMMODITY
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  WEIGHT
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  BUY
                  <Typography sx={{ fontSize: "1vw", mt: 1, fontWeight: 400 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  SELL
                  <Typography sx={{ fontSize: "1vw", mt: 1, fontWeight: 400 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
              </Box>

              <Box className={classes.tableContent}>
                {commodities?.map((commodity, idx) => {
                  const words = commodity.commodity_title.split(" ");
                  return (
                    <Box key={idx} className={classes.tableRow1}>
                      <Typography
                        sx={{
                          fontSize: "1vw",
                          justifyContent: "left",
                          fontWeight: "bold",
                        }}
                        className={classes.tableRowColumn}
                      >
                        {words.map((word, index) => {
                          if (index === 0) {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "2vw",
                                  fontWeight: "bold",
                                  paddingLeft: "30px",
                                }}
                              >
                                {word.toUpperCase()}
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "1vw",
                                  marginTop: "0.2rem",
                                  fontWeight: "bold",
                                }}
                              >
                                &nbsp;{word.toUpperCase()}
                              </span>
                            );
                          }
                        })}{" "}
                        &nbsp;
                        {commodity.unitLabel === "TTB"
                          ? ""
                          : commodity.displayPurity}
                      </Typography>
                      <Typography
                        style={{ fontSize: "2vw", fontWeight: "bold" }}
                        className={classes.tableRowColumn}
                      >
                        {commodity.unit} {commodity.unitLabel}
                      </Typography>
                      <Typography
                        style={{ fontSize: "2vw", fontWeight: "bold" }}
                        className={classes.tableRowColumn}
                      >
                        {!gold?.cur?.bid
                          ? "0.00"
                          : commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.bid
                                : gold?.cur?.bid,
                              commodity.commodity_title === "Silver"
                                ? spread.silverBidSpread
                                : spread.goldBidSpread,
                              commodity.buy_premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.buy_charge
                            )}
                      </Typography>
                      <Typography
                        style={{ fontSize: "2vw", fontWeight: "bold" }}
                        className={classes.tableRowColumn}
                      >
                        {!gold?.cur?.bid
                          ? "0.00"
                          : commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.ask
                                : gold?.cur?.ask,
                              commodity.commodity_title === "Silver"
                                ? spread.silverAskSpread
                                : spread.goldAskSpread,
                              commodity.premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.charges
                            )}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box className={classes.updatesContent}>
            <marquee
              style={{
                height: "60%",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                {!adminData?.isNewsEnable &&
                Object.keys(goldNews).length > 0 ? (
                  <Box
                    style={{
                      fontSize: "1.5vw",
                      fontFamily: "poppins",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Gold Price News</span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                  </Box>
                ) : (
                  news?.map((item, index) => {
                    return (
                      <Box
                        key={item._id}
                        style={{
                          fontSize: "1.5vw",
                          fontFamily: "poppins",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {item.newsTitle}
                        </span>
                        &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                        <span
                          style={{ paddingRight: "50px", paddingLeft: "50px" }}
                        >
                          {index === news.length - 1 ? "" : "|"}
                        </span>
                      </Box>
                    );
                  })
                )}
              </Box>
            </marquee>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </div>
  );
};

export default Home;
